import {sxAjax} from '@/commons/ajax';

class AbpUserConfigurationService {
  getAll=async () =>{
    var domain = window._env_?.api_url ?? "";
    console.log("domain==============>",domain);
    if (domain && domain.charAt(domain.length - 1) === '/') {
      const result = await sxAjax.get(`${domain}AbpUserConfiguration/GetAll`);
      return result;
    } else if (domain) {
      const result = await sxAjax.get(`${domain}/AbpUserConfiguration/GetAll`);
      return result;
    } else {
      const result = await sxAjax.get('AbpUserConfiguration/GetAll');
      return result;
    }
  }
}

export default new AbpUserConfigurationService();